<template>
  <div class="order">
    <div class="title">订单信息</div>
    <div class="form">
      <div class="inv-h-w">
        <div :class="['inv-h', Inv == index ? 'inv-h-se' : '']" @click="changeInv(index)" v-for="(item, index) in tablist"
          :key="index">
          {{ item.name }}
        </div>
      </div>
      <div class="">
        <div v-if="list.length == 0">
          <div class="nodata">暂无数据</div>
        </div>
        <div class="orderlist" v-for="(item, index) in list" :key="index">
          <div class="title">
            <div class="left">
              <span>{{ item.onlineName }}</span>
            </div>
            <div class="right">支付状态：{{ item.payStatus == 0 ? '未支付' : '已支付' }}<span style="margin-left:20px">支付方式：{{  item.payType == 0 ? "paypal" :  item.payType == 1 ?"预付款":item.payType == 2?'货到付款':item.payType == 3?'转账':item.payType == 4?'支付宝':item.payType == 5?'微信支付':'信用卡' }}</span><span style="margin-left:20px">{{ item.orderStatus
              == 2 ? '已收货' : item.orderStatus == 1 ? '已发货' : item.orderStatus == 0 ? '待发货' : item.orderStatus ==
                3 ? '待支付' : '关闭' }}</span></div>
          </div>
          <div class="main" v-for="(item2, index2) in item.details" :key="index2">
            <img :src="item2.goodPic" alt="" />
            <div class="text">
              <div class="name">
                <div class="names">{{ item2.goodName }}</div>
                <div class="price">{{currency.symbol}}{{ item2.itemPrice }}</div>
              </div>
              <div class="desc">
                <span>订单编号：{{ item2.orderNum }}</span>
                <span>数量：x{{ item2.goodNum }}</span>
              </div>

              <div class="ordertime">下单时间：{{ item.createTime }}</div>

            </div>
          </div>
          <div style="display: flex;">
            <div class="btns" @click="seeInfo(item)">详情</div>
            <div class="btns"  style="margin-left: 10px;" v-if="item.payType == 0 && item.orderStatus == 3" @click="toPay(item)">去支付</div>
          </div>
        </div>
      </div>
    </div>
    <div class="fenye">
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="currentChange"
        :page-size="query.pageSize">
      </el-pagination>
    </div>

    <el-dialog title="选择支付" :visible.sync="dialogVisible" width="30%">
      <PayPal :amount="amount" currency="AUD" :client="credentials" env="sandbox" :button-style="buttonStyle"
        @payment-authorized="paymentAuthorized" @payment-completed="paymentCompleted"
        @payment-cancelled="paymentCancelled">
      </PayPal>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="dialogVisibleinfo" width="50%">
      <orderinfo ref="orderinfo"></orderinfo>
    </el-dialog>

  </div>
</template>

<script>
  
import { orderlist, orderdetail,onlineget } from "@/request/api.js";
import PayPal from "vue-paypal-checkout";
import orderinfo from "@/views/information/components/orderinfo";
import {
  orderpaySuccessOrder,
} from "@/request/api.js";
export default {
  name: "order",
  components: {
    PayPal,
    orderinfo
  },
  data() {
    return {
      dialogVisibleinfo: false,
      dialogVisible: false,
      Inv: 0,
      // '全部','待发货','已发货','已收货',
      total: 0,
      amount: "1",
      credentials: {
        sandbox:
          "AYU3Ok_40gXENzZb5FOLrAgN57uMLp9MOaOFmRNIrPoBb0fVWDqKOQomeRAr-m5uBvaj3mDFMoa6e_Ny",
        production:
          "AdUzSCCq1rNlSYM9ca2q6M75BNW9moJADOU1VHl631tsdJPhDY-n8JdMimbpLp8iHfJ-jax64i9DqqqK",
      },
      buttonStyle: {
        label: "pay",
        size: "responsive",
        shape: "pill",
        color: "blue",
      },
      tablist: [
        {
          id: "",
          name: "全部",
        },
        {
          id: "0",
          name: "待发货",
        },
        {
          id: "1",
          name: "已发货",
        },
        {
          id: "2",
          name: "已收货",
        },
        {
          id: "3",
          name: "待支付",
        },
        {
          id: "4",
          name: "已支付",
        },
      ],
      query: {
        pageSize: 3,
        pageNum: 1,
        orderStatus: "",
        payStatus: ''
      },
      orderNum: '',
      list: [],
      orderInfodata: '',
      currency:{}
    };
  },
  created() {
    this.getData();

    onlineget().then(res=>{
      this.currency = res.data.currency
    })
  },
  methods: {
    getData() {
      orderlist(this.query).then((res) => {
        this.list = res.rows;
        this.total = res.total;
        console.log(this.total);
      });
    },
    changeInv(index) {
      if (this.Inv == index) {
        return;
      }

      this.Inv = index;

      if (this.Inv == 5) {
        this.query.payStatus = 1
        this.query.orderStatus = ''
      } else {
        this.query.payStatus = ''
        this.query.orderStatus = this.tablist[index].id
      }
      this.getData()
    },

    currentChange(e) {
      console.log(e);
      this.query.pageNum = e
      this.getData()
    },
    toPay(e) {
      console.log(e)

      this.amount = e.allPrice
      this.orderNum = e.orderNum

      this.dialogVisible = true


    },
    paymentAuthorized(data) {
      // 授权完成的回调，可以拿到订单id
      console.log(data);
    },
    closeBack() {

      this.$router.go(-1)


    },

    paymentCompleted(data) {
      console.log('支付成功的回调')
      console.log(data)
      //支付成功
      let query = {
        orderNum: this.orderNum,
        paymentId: data.id
      };
      orderpaySuccessOrder(query).then((res) => {
        this.$notify({
          title: "成功",
          message: "支付成功",
          type: "success",
        });
        this.$router.push({
          path: `/`,
        });
      });
    },

    paymentCancelled(data) {
      // 用户取消交易的回调
      this.$router.go(-1)

    },
    seeInfo(e) {
      orderdetail({ orderId: e.orderId }).then(res => {
        console.log(res)
        this.dialogVisibleinfo = true
        this.$nextTick(() => {
          this.$refs.orderinfo.init(res.data)
        })

      })
    }
  },
};
</script>

<style lang="scss" scoped>
.nodata {
  text-align: center;
  margin: 60px 0;
  color: #938888;
}

.inv-h-w {
  background-color: #ffffff;
  height: 50px;
  display: flex;
  cursor: pointer;
  width: 400px;
}

.inv-h {
  font-size: 15px;
  flex: 1;
  text-align: center;
  color: #727272;
  height: 50px;
  line-height: 50px;
}

.inv-h-se {
  color: #fc7474;
  border-bottom: 2px solid #fc7474;
}

.order {
  padding: 20px 40px;

  .title {
    margin-bottom: 15px;
  }
}

.btns {
  background: #e14343;
  color: #fff;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  width: 100px;
  cursor: pointer;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orderlist {
  padding: 15px 0 25px 0;
  border-bottom: 1px solid #e9e9e9;
  cursor: pointer;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      img {
        width: 25px;
        margin-right: 8px;
      }
    }

    .right {
      color: #807d7d;
      font-size: 14px;
    }
  }

  .main {
    display: flex;
    margin-bottom: 10px;

    img {
      width: 120px;
      margin-right: 20px;
    }

    .text {
      width: 100%;
    }

    .name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;

      .names {
        font-size: 20px;
        color: #2c2a2a;
      }

      .price {
        font-size: 24px;
        color: #e70012;
        font-weight: bold;
      }
    }

    .desc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 5px;

      span {
        font-size: 14px;
        color: #807d7d;
      }
    }

    .ordertime {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 10px;
      font-size: 15px;
      color: #807d7d;
    }
  }
}

.fenye {
  margin-top: 10px;
}
</style>
