<template>
  <div>
    <headertop Inv="5"></headertop>
    <div class="myInfo">
      <div class="left">
        <div class="list" :class="Inv == 1 ? 'active' : ''" @click="Inv = 1">
          <img src="../assets/ziliao_a.png" class="img" alt="" />
          <img src="../assets/ziliao.png" class="img1" alt="" />
          <span>基本资料</span>
        </div>
        <div class="list" :class="Inv == 2 ? 'active' : ''" @click="Inv = 2">
          <img src="../assets/dizhi_a.png" class="img" alt="" />
          <img src="../assets/dizhi.png" class="img1" alt="" />
          <span>地址管理</span>
        </div>
        <div class="list" :class="Inv == 3 ? 'active' : ''" @click="Inv = 3">
          <img src="../assets/dingdan_a.png" class="img" alt="" />
          <img src="../assets/dingdan.png" class="img1" alt="" />
          <span>订单管理</span>
        </div>
        <div class="list" @click="loginOut">
          <img src="../assets/dingdan_a.png" class="img" alt="" />
          <img src="../assets/dingdan.png" class="img1" alt="" />
          <span>退出登录</span>
        </div>
      </div>
      <div class="right">
        <div class="userBox">
          <div class="userleft">
            <img class="userTouxiang" :src="
              userData.headUrl ? userData.headUrl : '../assets/defaultTx.png'
            " alt="" />
            <div class="check">
              <img src="../assets/paizhao.png" alt="" />
              <span>更换头像</span>
              <el-upload class="avatar-uploader" :action="action" :show-file-list="false" :limit="1"
                :on-success="handleAvatarSuccess">
                <div class="name">上传</div>
              </el-upload>
            </div>
          </div>
          <div class="userRight">
            <div class="type" v-if="userData.levelName">
              <img src="../assets/huangguan.png" alt="" />
              <span>{{ userData.levelName }}</span>
            </div>
            <div class="name">姓名：{{ userData.realName }}</div>
            <div class="desc">
              昵称：{{ userData.nickName ? userData.nickName : "-" }}
            </div>
            <div class="desc">手机：{{ userData.phone }}</div>
            <div class="desc">
              邮箱：{{ userData.email ? userData.email : "-" }}
            </div>
          </div>
          <div class="goin" v-if="Inv == 1" @click="toPage('/informationmy')">
            <img :src="webFavicon" alt="">
            <span>进入因为信个人中心</span>
          </div>
        </div>
        <div class="userInfoMation" style="position: relative;">
          <!-- <userInfo
            v-show="Inv == 1"
            ref="userInfo"
            @changeMy="getUserInfo"
          ></userInfo> -->
          <addresss v-show="Inv == 2"></addresss>
          <el-button type="danger" @click="backpage" v-if="showBackBtn" style="position: absolute;bottom: 20px;left: 200px;">返回下单页</el-button>
          <order v-show="Inv == 3"></order>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import headertop from "../components/header.vue";
// import userInfo from "../components/userInfo.vue";
import addresss from "../components/addresss.vue";
import order from "../components/order.vue";
import { getmemberUserinfo, memberUserupdateInfo } from "@/request/api.js";
import { inwaySetall } from "@/request/api.js";
import bottom from "../components/bottom.vue";

export default {
  name: "home",
  components: {
    bottom,
    headertop,
    // userInfo,
    addresss,
    order,
  },
  data() {
    return {
      Inv: 1,
      userData: "",
      action: process.env.VUE_APP_BASE_RUL + "common/upload",
      showBackBtn: false,
      webFavicon: ''
    };
  },
  created() {
    if (this.$route.query.Inv) {
      this.Inv = this.$route.query.Inv
      this.showBackBtn = true
    }

    this.getUserInfo();
    this.getDefaultSet()
  },
  mounted() { },

  methods: {
    getDefaultSet() {
      inwaySetall().then((res) => {
        console.log(res);
        this.webFavicon = res.data.webFavicon;

      });
    },
    backpage() {
      this.$router.push({
        path: '/car',
      });
    },
    getUserInfo() {
      getmemberUserinfo().then((res) => {
        console.log(res);
        this.userData = res.data;
        // this.$refs.userInfo.init(res.data);
      });
    },
    handleAvatarSuccess(res) {
      this.userData.headUrl = res.data.url;
      memberUserupdateInfo(this.userData).then((res) => {
        this.$message.success("修改成功");
        this.getUserInfo()
      });
    },
    loginOut() {
      this.$confirm("是否确认退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        localStorage.removeItem('MemberAuthorization');
        this.$router.push({
          path: "/userlogin",
        });
      });
    },
    toPage(path) {
      this.$router.push({
        path: path,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.goin {
  position: absolute;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ff6464;
  font-size: 18px;
  font-weight: bold;

  img {
    height: 80px;
    width: auto;
    margin-right: 10px;
  }
}

.myInfo {
  width: 70%;
  margin: 0 auto;
  background: #f5f2f2;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  margin-top: 20px;
  position: relative;

  .userBox {
    padding: 20px 40px;
    box-sizing: border-box;
    background: #fff;
    display: flex;

    .userRight {
      .type {
        display: flex;
        align-items: center;
        color: #787878;

        img {
          width: 25px;
          margin-right: 10px;
        }
      }

      .name {
        font-size: 26px;
        margin: 10px 0;
        margin-bottom: 30px;
      }

      .desc {
        margin-bottom: 15px;
        margin-top: 5px;
        color: #787878;
      }
    }

    .userleft {
      margin-right: 40px;
      cursor: pointer;

      .userTouxiang {
        width: 200px;
        border-radius: 10px;
      }

      .check {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        width: 200px;
        color: #9a9a9a;
        position: relative;

        .avatar-uploader {
          position: absolute;
          z-index: 10;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          opacity: 0;

          .name {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 10;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
          }
        }

        img {
          width: 25px;
          margin: 10px;
        }
      }
    }
  }

  .right {
    display: inline-block;
    width: calc(100% - 160px);
    background: #ffffff;
    margin-left: 30px;
    box-sizing: border-box;
    box-shadow: 0 3px 3px #d9d9d9;
  }

  .left {
    width: 105px;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 3px 3px #d9d9d9;
    display: inline-block;

    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      font-size: 15px;
      border-bottom: 1px solid #e9e9e9;

      img {
        width: 30px;
        margin-bottom: 10px;
      }

      .img {
        display: block;
      }

      .img1 {
        display: none;
      }
    }

    .active {
      background: #fc7474;
      color: #fff;
    }

    .active {
      .img {
        display: none;
      }

      .img1 {
        display: block;
      }
    }
  }
}
</style>
