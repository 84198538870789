<template>
  <div>
    <div class="orderinfo">
      <div class="">
        <div class="listA">
          <div
            class="ls"
            v-for="(item, index) in orderInofData.details"
            :key="index"
          >
            <img :src="item.goodPic" class="goodsImg" alt="" />
            <div class="title">
              <span class="name">{{ item.goodName }}</span>
            </div>
            <div class="smdesc">
              <span>折扣：{{ item.discount }}</span>
            </div>
            <!-- <div class="smdesc">
              <span
                >售后说明：加热加热加热加热加热加热加热加热加热加热加热加热加热...</span
              >
            </div> -->

            <!-- <div class="guige">
              <span>规格：</span>
              <span class="gui">1个</span>
              <span class="gui">1笼</span>
            </div> -->
            <div class="addCar">
              <span @click.stop>
                <span>x {{ item.goodNum }}</span>
               
              </span>
              <div class="right">
                <div class="price">
                  <span class="red">￥{{ item.itemPrice }}</span>
                </div>
                <!-- <img src="../assets/shopCar.png" alt="" /> -->
              </div>
            </div>
          </div>
          <div class="payBox">
            <div class="check">
              <!-- <el-checkbox v-model="checked">是否自提</el-checkbox> -->
            </div>
            <div class="desc">创建时间：{{ orderInofData.createTime }}</div>

            <div class="desc">备注：{{ orderInofData.remark }}</div>
            <div class="desc" style="margin-bottom:10px">
              支付方式：{{
                orderInofData.payType == 0 ? "paypal" :  orderInofData.payType == 1 ?"预付款":orderInofData.payType == 2?'货到付款':orderInofData.payType == 3?'转账':orderInofData.payType == 4?'支付宝':orderInofData.payType == 5?'微信支付':'信用卡'
              }}
            </div>
            <div class="desc" style="margin-bottom:10px">
              配送方式：{{
                orderInofData.deliveryType == 0 ? " 送货上门" :  orderInofData.deliveryType == 1 ?"配送站自提":'仓库自提'
              }}
            </div>
            <div v-if="orderInofData.deliveryType == 0">
              <div class="desc">
                快递地址：{{ orderInofData.detailAddress }}
              </div>
              <div class="desc">联系人：{{ orderInofData.linkName }}</div>
              <div class="desc">联系电话：{{ orderInofData.linkPhone }}</div>
            </div>
            <div v-if="orderInofData.deliveryType == 2">
              <div class="desc">
                自提地址：{{ orderInofData.storeSelfPoint.address }}
              </div>
              <div class="desc">
                负责人：{{ orderInofData.storeSelfPoint.linkName}}
              </div>
              <div class="desc">
                联系电话：{{ orderInofData.storeSelfPoint.linkPhone }}
              </div>
              <div class="desc">
                工作时间：{{ orderInofData.storeSelfPoint.workTime }}
              </div>
            </div>
            <div class="val">合计：￥{{ orderInofData.allPrice }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderinfo",
  components: {},
  data() {
    return {
      num: 1,
      checked: false,
      orderInofData: "",
    };
  },
  created() {},
  methods: {
    tableClass({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        //奇数行，序号不能被2整除
        return "even-row";
      } else {
        return "";
      }
    },
    handleChange(e) {
      console.log(e);
    },
    init(e) {
      console.log(e);
      this.orderInofData = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  margin-bottom: 20px;
}
.orderinfo {
  padding: 20px 20px 10px 20px;
  .titless {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #fc7474;
    margin-bottom: 10px;
    .iconfont {
      margin-right: 4px;
    }
  }
}
.fenye {
  margin-top: 10px;
}

.listA {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  cursor: pointer;
}
.ls {
  background: #fff;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  padding-left: 190px;
  position: relative;
  margin-bottom: 10px;

  .goodsImg {
    position: absolute;
    left: 10px;
    width: 165px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name {
      font-weight: bold;
      font-size: 18px;
    }
    .desc {
      font-size: 14px;
      color: #a9a7a7;
      display: flex;
      align-items: center;
      img {
        width: 20px;
      }
    }
  }
  .addCar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    img {
      width: 40px;
    }
  }
  .guige {
    margin-top: 5px;
    font-size: 14px;
    color: #a9a7a7;
    .gui {
      background: #e9e9e9;
      margin-left: 10px;
      padding: 3px 10px;
      border-radius: 5px;
      font-size: 13px;
    }
  }
  .price {
    margin-top: 5px;
    .red {
      color: red;
      font-size: 22px;
      font-weight: bold;
      margin-right: 4px;
    }
    .num {
      font-size: 13px;
      color: #a9a7a7;
      margin-left: 5px;
    }
  }
  .smdesc {
    margin-top: 5px;
    span {
      font-size: 14px;
      color: #a9a7a7;
    }
  }
}

.payBox {
  background: #fff;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  position: relative;

  .desc {
    margin-bottom: 5px;
  }

  .check {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .val {
    text-align: right;
    font-size: 22px;
    margin-bottom: 20px;
  }

  .btn {
    text-align: right;
  }
}
</style>
